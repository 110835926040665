import React from "react";
import { GlobalState, LiftingState, RegistrationState, MeetState, RecordsState } from "../../types/stateTypes";
import { Entry, LiftStatus, Lift, Language } from "../../types/dataTypes";
import { getEntriesInFlight, getLiftingOrder } from "../../logic/liftingOrder";
import { checkExhausted } from "../../types/utils";
import { getWeightClassStr, getWeightClassLbsStr } from "../../reducers/meetReducer";
import {
  liftToAttemptFieldName,
  liftToStatusFieldName,
  getBest3BenchKg,
  getBest3SquatKg,
  getBest3DeadliftKg,
  mapSexToClasses,
} from "../../logic/entry";

import styles from "./StreamOverlayView.module.scss";
import { isRecordAttempt, getUpdatedRecordState } from "../../logic/records/records";
import { connectWithDelay } from "../DelayedConnectedComponent";

// How long the stream overlay lags behind the state. This is nice for the stream so that when the white lights show up the viewer can see who it was for.
export const STREAM_OVERLAY_UPDATE_DELAY_MS = 5000;

interface StateProps {
  lifting: LiftingState;
  registration: RegistrationState;
  meet: MeetState;
  entriesInFlight: Array<Entry>;
  updatedRecordState: RecordsState;
  language: Language;
}

interface LiftResultBubbleProps {
  liftAmount: number;
  liftResult: LiftStatus;
}

class LiftResultBubble extends React.Component<LiftResultBubbleProps> {
  render() {
    // todo, make this logic nicer
    const resultColor = this.props.liftResult === 0 ? "#2f2b2b" : this.props.liftResult === 1 ? "green" : "#990000";
    const value = this.props.liftAmount === 0 ? "-" : `${this.props.liftAmount}`;

    return (
      <div style={{ backgroundColor: resultColor }} className={styles.LiftResultBubble}>
        <span>{value}</span>
      </div>
    );
  }
}

interface BestLiftProps {
  isActive: boolean;
  isRecordAttempt: boolean;
  liftType: string;
  liftAmount: number;
  displayBottomBorder: boolean;
}

class BestLiftView extends React.Component<BestLiftProps> {
  render() {
    return (
      <div
        className={this.props.isActive ? styles.BestLiftContainerActive : styles.BestLiftContainer}
        style={{ borderBottom: this.props.displayBottomBorder ? "solid" : "" }}
      >
        <span className={this.props.isRecordAttempt ? styles.CurrentLiftAnimate : undefined}>
          {this.props.liftType}
        </span>
        <span>{this.props.liftAmount}</span>
      </div>
    );
  }
}

// Scuffed ordinal suffix calculation
/*
function getOrdinalSuffix(i: number): string {
  const j = i % 10;
  const k = i % 100;
  if (j === 1 && k !== 11) {
    return "st";
  }
  if (j === 2 && k !== 12) {
    return "nd";
  }
  if (j === 3 && k !== 13) {
    return "rd";
  }
  return "th";
}

// Mostly copy pasted from the LiftingTable. Consider stripping out and reusing this.
function getPlace(categoryResults: CategoryResults[], entry: Entry): number {
  if (entry.guest) {
    return -1;
  }

  for (let i = 0; i < categoryResults.length; i++) {
    const result = categoryResults[i];
    if (result.category.division !== entry.divisions[0]) {
      continue;
    }

    const catEntries = result.orderedEntries;
    for (let j = 0; j < catEntries.length; j++) {
      const catEntry = catEntries[j];

      if (catEntry.id === entry.id) {
        // We can use the index into the array as their place, since it sorted and guests will be last in the array
        return j + 1;
      }
    }
  }
  // Shouldn't happen. To be safe, don't throw
  return 0;
}

function getTrophyColour(place: number): string {
  if (place === 1) {
    return "gold";
  }
  if (place === 2) {
    return "lightgray";
  }
  if (place === 3) {
    return "#cd7f32";
  }
  return "white";
}
*/

class StreamOverlayView extends React.Component<StateProps> {
  render() {
    const now = getLiftingOrder(this.props.entriesInFlight, this.props.lifting);

    // If there is no lifer, don't render anything for now
    if (now.currentEntryId === null) {
      return null;
    }

    const entryIndex = this.props.registration.lookup[now.currentEntryId];
    const entry = this.props.registration.entries[entryIndex];
    const lift = this.props.lifting.lift;

    const fieldKg = liftToAttemptFieldName(lift);
    const fieldResults = liftToStatusFieldName(lift);

    const lifts = entry[fieldKg];
    const liftResults = entry[fieldResults];

    const bestSquat = getBest3SquatKg(entry);
    const bestBench = getBest3BenchKg(entry);
    const bestDeadlift = getBest3DeadliftKg(entry);

    /*     const results = getFinalResults(
      this.props.registration.entries,
      this.props.meet.weightClassesKgMen,
      this.props.meet.weightClassesKgWomen,
      this.props.meet.weightClassesKgMx,
      this.props.meet.combineSleevesAndWraps
    );

    const place = getPlace(results, entry);
    // Only display current place if they've actually attempted any lifts.
    const hasMadeAttempt = entry.squatStatus[0] !== 0 || entry.benchStatus[0] !== 0 || entry.deadliftStatus[0] !== 0; */

    const isLiftReccordAttempt = isRecordAttempt(
      this.props.updatedRecordState,
      this.props.meet,
      entry,
      lift,
      now.attemptOneIndexed,
      this.props.language
    );

    // Can only set total records on deadlift
    const isTotalRecordAttempt =
      lift === "D" &&
      isRecordAttempt(
        this.props.updatedRecordState,
        this.props.meet,
        entry,
        "Total",
        now.attemptOneIndexed,
        this.props.language
      );

    const isAttemptingRecord = isLiftReccordAttempt || isTotalRecordAttempt;
    const recordAttemptText = isAttemptingRecord
      ? entry.canBreakRecords
        ? "South Island Record"
        : "Unofficial Record"
      : "";

    const trimmedName = entry.name.length >= 28 ? entry.name.substr(0, 25) + "..." : entry.name;

    return (
      <div className={styles.Container}>
        <div className={styles.MainContainer} style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <div className={styles.TitleContainer}>
            <h2 className={styles.LifterName}>{trimmedName}</h2>
          </div>
          {/* Content area + best lifts on the right */}
          <div className={styles.ContentAreaContainer}>
            {/* Main content area */}
            <div className={styles.MainContentAreaContainer}>
              <div>
                {/* Subtitle */}
                <span className={styles.LifterSubTitle}>{entry.divisions.join(", ")}</span>
                <span className={styles.LifterSubTitle}>{this.getWeightClassString(entry)}</span>
                {/* Only display place if they have made an attempt at any lifts */}
                {/* Disable for now, several minor issues need to be fixed */}
                {/*                 {hasMadeAttempt ? (
                  <span className={styles.LifterSubTitle}>
                    <FontAwesomeIcon
                      style={{ color: getTrophyColour(place), marginLeft: "5px", fontSize: "20px" }}
                      icon={faTrophy}
                    />
                    {` ${place}${getOrdinalSuffix(place)}`}
                  </span>
                ) : undefined} */}
                {entry.instagram ? <span className={styles.LifterSubTitle}> {`@${entry.instagram}`} </span> : ""}
              </div>

              {/* Current lift area */}
              <div className={styles.CurrentLiftParentContainer}>
                <span className={styles.CurrentLiftType}>
                  {this.convertLiftToNiceString(this.props.lifting.lift)} #{now.attemptOneIndexed}{" "}
                  <span className={styles.RecordAttemptNotice}>{recordAttemptText}</span>
                </span>
                <div className={styles.CurrentLiftContainer}>
                  {/* use .slice() since the array is of length 5, but we only  care for 3 lifts */}
                  {lifts.slice(0, 3).map((value, index) => (
                    <LiftResultBubble key={index} liftAmount={value} liftResult={liftResults[index]} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {/*  Render best lifts section */}
          <div style={{ display: "flex", justifyContent: "space-evenly", flexDirection: "column" }}>
            <BestLiftView
              isActive={lift === "S"}
              isRecordAttempt={lift === "S" && isLiftReccordAttempt}
              liftType="Squat"
              liftAmount={bestSquat}
              displayBottomBorder={true}
            />
            <BestLiftView
              isActive={lift === "B"}
              isRecordAttempt={lift === "B" && isLiftReccordAttempt}
              liftType="Bench"
              liftAmount={bestBench}
              displayBottomBorder={true}
            />
            <BestLiftView
              isActive={lift === "D"}
              isRecordAttempt={lift === "D" && isLiftReccordAttempt}
              liftType="Deadlift"
              liftAmount={bestDeadlift}
              displayBottomBorder={true}
            />
            <BestLiftView
              liftType="Total"
              isRecordAttempt={isTotalRecordAttempt}
              isActive={false}
              liftAmount={bestSquat + bestBench + bestDeadlift}
              displayBottomBorder={false}
            />
          </div>
        </div>
      </div>
    );
  }
  renderRecordAttemptNotice(): React.ReactNode {
    throw new Error("Method not implemented.");
  }

  convertLiftToNiceString(lift: Lift): string {
    switch (lift) {
      case "S":
        return "Squat";
      case "B":
        return "Bench";
      case "D":
        return "Deadlift";
      default:
        checkExhausted(lift);
    }
    return "";
  }

  getWeightClassString(entry: Entry): string {
    // Roughly copied from LiftingTable. Could be made into a reuseable function too
    const bw = entry.bodyweightKg;
    const classesForSex = mapSexToClasses(
      entry.sex,
      this.props.meet.weightClassesKgMen,
      this.props.meet.weightClassesKgWomen,
      this.props.meet.weightClassesKgMx
    );
    const weightClass = this.props.meet.inKg
      ? getWeightClassStr(classesForSex, bw)
      : getWeightClassLbsStr(classesForSex, bw);

    return weightClass;
  }
}

const mapStateToProps = (state: GlobalState): StateProps => {
  const entriesInFlight = getEntriesInFlight(
    state.lifting.day,
    state.lifting.platform,
    state.lifting.flight,
    state.registration.entries
  );

  return {
    lifting: state.lifting,
    registration: state.registration,
    meet: state.meet,
    entriesInFlight,
    language: state.language,
    updatedRecordState: getUpdatedRecordState(state.records, state.meet, state.registration, state.language),
  };
};

export default connectWithDelay(STREAM_OVERLAY_UPDATE_DELAY_MS, StreamOverlayView, mapStateToProps);
